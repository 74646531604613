@import url("//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css");
@import "./nomalize.css";
@import "./mixin";

* {
  font-family: "Spoqa Han Sans Neo", sans-serif;

  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;

  ::selection {
    color: white;

    background: #2957f2;
  }
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

ol,
ul {
  padding: 0;
  margin: 0;

  list-style: none;
}

a {
  all: unset;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    text-decoration: none;
  }

  &:link {
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}
