@import '../../../styles/mixin';
@import '../../../styles/functions';
@import '../../../design-system/color-palette/color-palette';

.section {
  padding: 72px 32px 0;

  background: $base_gray2;

  @include desktop {
    display: flex;

    height: 880px;
    padding: 0;
  }

  .subTitle {
    margin-top: 16px;

    color: $base_light;
  }

  .caption {
    margin-top: 8px;

    color: $base_gray6;
  }

  .leftSection {
    position: relative;

    .desktopSection4Image {
      width: 355px;
      height: 650px;

      @include animationHide();
      transition-delay: 0.4s;
    }
  }

  .rightSection {
    display: flex;

    align-items: center;

    .content {
      margin-left: 40px;

      @include animationHide();

      .desktopTitle {
        @include desktopTitle();
      }

      .desktopSubTitle {
        @include desktopSubTitle();
        margin-top: 28px;
      }

      .desktopCaption {
        margin-top: 16px;

        color: $base_gray6;
      }
    }
  }

  .gray {
    color: $base_16;
  }
}

.view {
  @include animationView();
}

.imageView {
  @include animationView();
}

.isIEOrEdge {
  @include animationView();
}
