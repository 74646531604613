@import "src/design-system/color-palette/color-palette";
@import "src/styles/variables";
@import "src/styles/mixin";

.footer {
  padding: 72px 32px calc(env(safe-area-inset-bottom) + 96px);

  background: $base_gray3;

  @include desktop {
    padding: 96px 32px;
  }

  .content {
    @include desktop {
      max-width: $desktop-width;
      margin: 0 auto;
    }

    .navWrapper {
      @include desktop {
        display: flex;
      }

      nav {
        &:first-child {
          margin-bottom: 48px;

          @include desktop {
            margin-right: 240px;
            margin-bottom: 72px;
          }
        }
      }

      .subTitle3 {
        color: $base_gray6;
      }

      .navItem {
        margin-top: 16px;

        cursor: pointer;
      }

      .navItemText {
        display: inline-block;

        color: $base_gray6;

        transition: color 0.15s ease-in-out;

        @media (hover: hover) {
          &:hover {
            color: $base_light;
            text-decoration: underline;
          }
        }
      }

      .desktopAppDownText {
        display: flex;

        align-items: center;

        @media (hover: hover) {
          &:hover {
            .arrowIcon {
              fill: $base_light;
            }
          }
        }
      }

      .arrowIcon {
        width: 16px;
        height: 16px;
        margin-left: 4px;

        fill: $base_gray6;

        transition: transform 0.15s ease-in-out;
      }

      .isOpenArrow {
        transform: rotate(180deg);
      }

      .desktopAppDown {
        position: relative;

        .appDownMenu {
          position: absolute;
          top: 20px;

          box-sizing: border-box;
          display: flex;

          align-items: center;
          justify-content: center;

          width: 257px;
          height: 72px;

          background: url("../../images/toolbar.png") no-repeat center;
          background-size: cover;

          .googleLink {
            display: inline-block;

            width: 102px;
            height: 22px;

            background-image: url("../../images/googleplay-gray.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            transition: background 0.15s ease-in-out;

            &:hover {
              background-image: url("../../images/google-play-hover.png");
            }
          }

          .appleLink {
            display: inline-block;

            width: 91px;
            height: 22px;

            background-image: url("../../images/appstore-gray.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

            transition: background 0.15s ease-in-out;

            &:hover {
              background-image: url("../../images/appstore-hover.png");
            }
          }

          .border {
            height: 45%;
            margin: 0 16px;

            border-right: 1px solid $base_gray4;
          }
        }
      }
    }

    .serviceInfo {
      margin-top: 48px;

      @include desktop {
        margin-top: 0;
      }

      .company {
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        color: $base_gray6;
      }

      .desktopCompany {
        color: $base_gray6;
      }

      .menus {
        display: flex;

        align-items: center;

        .verticalBorder {
          margin: 0 8px;

          font-size: 12px;
          color: $base_gray4;

          @include desktop {
            font-size: 14px;
          }
        }
      }

      .privacyPolicyText {
        display: inline-block;

        color: $base_gray6;

        transition: color 0.15s ease-in-out;

        .link {
          cursor: pointer;

          @media (hover: hover) {
            &:hover {
              color: $base_light;
              text-decoration: underline;
            }
          }
        }
      }

      .gray {
        color: $base_gray6;
      }
    }

    .prnd {
      display: flex;

      justify-content: space-between;

      margin-top: 4px;

      .prndLogo {
        width: 48px;
        height: 18px;

        @include desktop {
          width: 80px;
          height: 30px;
        }
      }
    }
  }
}
