@import '../../../styles/mixin';
@import '../../../styles/functions';
@import '../../../design-system/color-palette/color-palette';

.section {
  box-sizing: border-box;

  width: 100%;
  padding: 72px 0 0 32px;
  overflow: hidden;

  background: $base_gray2;

  @include desktop {
    display: flex;

    align-items: center;
    justify-content: center;

    height: 800px;
    padding: 0;
  }

  .mobileTitle {
    @include mobileTitle();
    @include animationHide();
  }

  .safeTradeCarouselWrapperMobile {
    margin-top: 40px;
    margin-bottom: 72px;

    @include animationHide();
    transition-delay: 0.4s;
  }

  .desktopTitle {
    @include desktopTitle();
    @include animationHide();
    text-align: center;
  }

  .safeTradeCarouselWrapperDesktop {
    display: flex;

    margin-top: 64px;

    .safeTradeCard1 {
      @include animationHide();
      transition-delay: 0.3s;
    }

    .safeTradeCard2 {
      @include animationHide();
      transition-delay: 0.6s;
    }

    .safeTradeCard3 {
      @include animationHide();
      transition-delay: 0.9s;
    }
  }
}

.view {
  @include animationView();
}

.imageView {
  @include animationView();
}

.isIEOrEdge {
  @include animationView();
}
