@import '../../../styles/mixin';
@import '../../../styles/functions';
@import '../../../design-system/color-palette/color-palette';

.section {
  box-sizing: border-box;

  width: 100%;
  max-width: 100vw;
  padding: 72px 0 72px 32px;
  overflow: hidden;

  @include desktop {
    display: flex;

    height: 776px;
    padding: 0;
  }

  .mobileTitle {
    @include mobileTitle();
  }

  .mobileContent {
    @include animationHide();
  }

  .carousel {
    @include animationHide();
    transition-delay: 0.4s;
  }

  .subTitle {
    margin-top: 16px;

    color: $base_light;
  }

  .caption {
    margin-top: 8px;

    color: $base_gray6;
  }

  .leftSection {
    position: relative;

    flex-basis: 50%;

    .content {
      position: absolute;
      top: 288px;
      right: 142px;

      @include animationHide();

      .desktopTitle {
        @include desktopTitle();
      }

      .desktopSubTitle {
        @include desktopSubTitle();
        margin-top: 24px;
      }
    }
  }

  .rightSection {
    display: flex;

    flex-basis: 50%;

    align-items: center;

    .dealerProfileImage1 {
      z-index: 1;

      width: 260px;
      height: 424px;
      margin-right: 22px;
      margin-left: 40px;

      border-radius: 4px;
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);

      transition-delay: 0.3s;

      @include animationHide();
    }

    .dealerProfileImage2 {
      width: 260px;
      height: 424px;
      margin-right: 22px;

      border-radius: 4px;
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);

      transition-delay: 0.6s;

      @include animationHide();
    }
  }
}


.view {
  @include animationView();
}

.imageView {
  @include animationView();
}

.isIEOrEdge {
  @include animationView();
}
