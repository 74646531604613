@import '../../../../styles/mixin';
@import '../../../../styles/functions';
@import '../../../../design-system/color-palette/color-palette';

.subTitle {
  margin-top: 16px;

  color: $base_light;
}

.caption {
  margin-top: 8px;

  color: $base_gray6;
}

.section5Desktop {
  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 801px;
  margin: 0 auto;

  .leftSection {
    position: relative;

    .content {
      @include animationHide();

      .desktopTitle {
        @include desktopTitle();
      }

      .desktopSubTitle {
        @include desktopSubTitle();
        margin-top: 24px;
      }
    }
  }

  .rightSection {
    display: flex;

    align-items: center;

    width: 340px;

    isolation: isolate;

    .dealerProfileImage1 {
      z-index: 1;

      width: 260px;
      height: 424px;
      margin-right: 22px;
      margin-left: 40px;

      border-radius: 4px;
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);

      transition-delay: 0.3s;

      @include animationHide();
    }

    .dealerProfileImage2 {
      width: 260px;
      height: 424px;
      margin-right: 22px;

      border-radius: 4px;
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);

      transition-delay: 0.6s;

      @include animationHide();
    }
  }
}

.view {
  @include animationView();
}

.imageView {
  @include animationView();
}

.isIEOrEdge {
  @include animationView();
}
