@import '../color-palette/color-palette.scss';

.typography {
  display: block;

  margin: 0;

  font-stretch: normal;
  color: #272e40;
  letter-spacing: normal;
}

.Headline_1 {
  font-size: 40px;
  font-weight: bold;
  line-height: 64px;
}

.Headline_2 {
  font-size: 32px;
  font-weight: bold;
  line-height: 52px;
}

.Headline_3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 40px;
}

.Headline_3_L {
  font-size: 24px;
  font-weight: 300;
  line-height: 40px;
}

.Subtitle_1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
}

.Subtitle_2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
}

.Subtitle_3 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

.Subtitle_4 {
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
}

.Body_1 {
  font-size: 16px;
  line-height: 28px;
}

.Body_2 {
  font-size: 14px;
  line-height: 24px;
}

.Caption {
  font-size: 12px;
  line-height: 20px;
}
