.rippleParent {
  overflow: hidden;

  transform: translate3d(0, 0, 0);

  backface-visibility: hidden;
}

.rippleSpan {
  position: absolute;

  display: inline-block;

  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;

  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;

  animation: ripple 0.9s;
}

@keyframes ripple {
  from {
    opacity: 1;

    transform: scale(0);
  }

  to {
    opacity: 0;

    transform: scale(10);
  }
}

.dummy {
  display: none;
}
