@import '../../../../styles/mixin';
@import '../../../../styles/functions';
@import '../../../../design-system/color-palette/color-palette';

.section4Desktop {
  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 801px;
  height: 880px;
  padding: 0;
  margin: 0 auto;
}

.leftSection {
  position: relative;

  transform: translateX(-40px);

  .desktopSection4Image {
    width: 355px;
    height: 650px;

    @include animationHide();
    transition-delay: 0.4s;
  }
}

.rightSection {
  display: flex;

  align-items: center;

  .content {
    @include animationHide();

    .desktopTitle {
      @include desktopTitle();
    }

    .desktopSubTitle {
      @include desktopSubTitle();
      margin-top: 28px;
    }

    .desktopCaption {
      margin-top: 16px;

      color: $base_gray6;
    }
  }
}

.view {
  @include animationView();
}

.imageView {
  @include animationView();
}

.isIEOrEdge {
  @include animationView();
}
