@import '../../../../styles/mixin';
@import '../../../../styles/functions';
@import '../../../../design-system/color-palette/color-palette';

.mobileTitle {
  @include mobileTitle();
}

.mobileContent {
  @include animationHide();
}

.carousel {
  @include animationHide();
  transition-delay: 0.4s;
}

.subTitle {
  margin-top: 16px;

  color: $base_light;
}

.view {
  @include animationView();
}

.imageView {
  @include animationView();
}

.isIEOrEdge {
  @include animationView();
}
