@import "../../../styles/mixin";
@import "../../../styles/functions";
@import "../../../design-system/color-palette/color-palette";

.section {
  padding: 72px 32px 0;

  @include desktop {
    display: flex;

    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 801px;
    height: 880px;
    padding: 0;
    margin: 0 auto;
  }

  .mobileContent {
    @include animationHide();
  }

  .mobileTitle {
    @include mobileTitle();
  }

  .subTitle {
    margin-top: 16px;

    color: $base_light;
  }

  .section3MobileImage {
    display: block;

    width: getVw(236);
    margin: 0 auto;
    margin-top: 32px;

    @include animationHide();
    transition-delay: 0.4s;
  }

  .leftSection {
    position: relative;

    .content {
      @include animationHide();

      .desktopTitle {
        @include desktopTitle();
      }

      .desktopSubTitle {
        @include desktopSubTitle();
        margin-top: 24px;
      }
    }
  }

  .rightSection {
    display: flex;

    align-items: center;

    .desktopSection3Image {
      width: 355px;
      height: 650px;

      @include animationHide();
      transition-delay: 0.4s;
    }
  }

  .gray {
    color: $base_16;
  }
}

.view {
  @include animationView();
}

.imageView {
  @include animationView();
}

.isIEOrEdge {
  @include animationView();
}
