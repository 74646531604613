.emoji {
  position: relative;
  top: -2px;

  display: inline-block;

  width: 50px;
  height: 50px;

  vertical-align: middle;

  object-fit: contain;
}
