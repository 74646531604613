@import "../../../../styles/mixin";
@import "../../../../styles/functions";
@import "../../../../design-system/color-palette/color-palette";

.mobileContent {
  @include animationHide();

  .mobileTitle {
    @include mobileTitle();
  }

  .subTitle {
    margin-top: 16px;

    color: $base_light;
  }

  .caption {
    margin-top: 8px;

    color: $base_gray6;
  }
}

.section4MobileImage {
  display: block;

  width: getVw(236);
  margin: 0 auto;
  margin-top: 32px;

  @include animationHide();
  transition-delay: 0.4s;
}

.view {
  @include animationView();
}

.imageView {
  @include animationView();
}
