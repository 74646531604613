@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.faq {
  margin-bottom: 64px;

  @include desktop {
    margin-bottom: 88px;
  }

  .paragraph {
    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  .link {
    color: $brandcolor_primary;
    text-decoration: underline;

    cursor: pointer;
  }
}
