@import "../../styles/mixin";
@import "../../styles/variables";
@import "../../styles/animations";
@import "../../design-system/color-palette/color-palette";

.container {
  padding: 0 32px;
  margin-top: 56px;

  animation: copyWriteFadeinUp 0.5s;

  @include desktop {
    max-width: $desktop-width;
    margin: 0 auto;
    margin-top: 64px;
  }

  .titleWrapper {
    padding-top: 40px;
    margin-bottom: 16px;

    @include desktop {
      padding-top: 144px;
      margin-bottom: 48px;
    }
  }

  .desktopTitle {
    margin: 0;

    font-size: 40px;
    line-height: 74px;
    color: $base_dark;
  }

  .br {
    width: 100%;

    border-bottom: 1px solid $base_gray3;
  }
}
