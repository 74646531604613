@import "../../../../styles/functions";
@import "../../../../styles/mixin";

.safeTradeCard {
  box-sizing: border-box;
  display: flex;

  flex-direction: column;

  justify-content: center;

  height: getVw(240);
  padding: 24px;

  border-radius: 4px;
  box-shadow: -10px 10px 15px rgba(0, 0, 0, 0.15);

  @include desktop {
    box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);
  }

  .descriptionWrapper {
    margin-top: getVh(14);

    white-space: pre-line;

    @include desktop {
      margin-top: 25px;
    }
  }

  &:first-child {
    .descriptionWrapper {
      opacity: 0.8;
    }
  }

  @include desktop {
    width: 296px;
    height: 296px;
    padding: 32px;

    &:not(:last-child) {
      margin-right: 32px;
    }
  }

  .title {
    margin: 0;

    font-size: 18px;
    line-height: 28px;

    @include desktop {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .image {
    align-self: center;

    width: getVw(88);
    height: getVw(88);
    margin-top: getVh(14);

    @include desktop {
      width: 96px;
      height: 96px;
      margin-top: 25px;
    }
  }
}
