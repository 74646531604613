@import 'src/design-system/color-palette/color-palette';
@import 'src/styles/mixin';

.otherQuestion {
  margin: 32px 0 72px;

  @include desktop {
    margin: 72px 0 152px;
  }

  .title {
    color: $base_light;
  }

  .subTitle {
    margin-top: 8px;

    color: $base_light;

    @include desktop {
      margin-top: 16px;
    }
  }

  .icon {
    width: 16px;
    height: 16px;

    fill: $base_gray4;
  }

  .buttonWrapper {
    margin-top: 16px;

    @include desktop {
      margin-top: 32px;
    }
  }

  .downloadButton {
    display: inline-flex;

    padding: 12px;

    font-size: 12px;
    line-height: 16px;
  }
}
