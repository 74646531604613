@import '../../../../design-system/color-palette/color-palette';
@import '../../../../styles/mixin';

.download {
  @include mobile {
    display: none;
  }
  box-sizing: border-box;
  display: inline-flex;

  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: 4px;

  transition: border 0.15s ease-in-out;

  &:first-child {
    margin-right: 12px;
  }

  @media (hover: hover) {
    &:hover {
      border-color: $base_white;
    }
  }
}

.main {
  width: 148px;
  height: 57px;

  background: rgba($base_white, 0.1);
  border: 1px solid rgba($base_white, 0.32);

  .googlePlay {
    width: 116px;
    height: 25px;
  }

  .appstore {
    width: 104px;
    height: 25px;
  }
}

.qna {
  width: 135px;
  height: 48px;

  background: $base_white;
  border: 1px solid $base_gray3;
  box-shadow: 0 2px 4px $base_8;

  .googlePlay {
    width: 102px;
    height: 22px;
  }

  .appstore {
    width: 91px;
    height: 22px;
  }
}
