@import "../color-palette/color-palette.scss";

.button {
  /* defaults */
  display: flex;

  align-items: center;
  justify-content: center;

  cursor: pointer;

  border-radius: 4px;
  outline: none;

  transition: 0.2s all;

  -webkit-tap-highlight-color: transparent;

  .marginRight {
    display: inline;

    margin-right: 4px;
  }

  .marginLeft {
    display: inline;

    margin-left: 4px;
  }
}

.primaryBrandcolor {
  color: $base_white;

  background-color: $brandcolor_primary;
  border: none;
  box-shadow: 0 2px 4px $brandcolor_32;

  @media (hover: hover) {
    &:hover {
      background-color: $brandcolor_light;
      box-shadow: 0 2px 4px $brandcolor_32;
    }
  }

  &:active {
    background-color: $brandcolor_dark;
    box-shadow: none;
  }
}

.secondaryGray {
  color: $base_gray6;

  background-color: $base_white;
  border: 1px solid $base_gray3;
  box-shadow: 0 2px 4px $base_8;

  @media (hover: hover) {
    &:hover {
      background-color: $base_gray1;
    }
  }

  &:active {
    background-color: $base_gray2;
  }
}

.textBrandcolor {
  color: $brandcolor_primary;

  /* text-brandcolor는 disabled 상태에도 배경색이 없게 한다. */
  background-color: transparent !important;
  border: none;
}

.big {
  padding: 16px;

  font-size: 16px;
  line-height: 24px;
}

.tiny {
  padding: 8px;

  font-size: 12px;
  line-height: 16px;
}

.disabled {
  color: $base_gray4 !important;

  cursor: auto;

  background-color: $base_gray2;
  border: none !important;
  box-shadow: none !important;

  @media (hover: hover) {
    &:hover {
      background-color: $base_gray2;
    }
  }
}
