@import "../../../../styles/functions";
@import "../../../../styles/mixin";

.carousel {
  padding-right: getVw(145);
  margin-top: 40px;

  :global {
    .swiper-container {
      overflow: visible !important;
    }
  }

  .firstImage {
    z-index: 1;
  }

  .dealerProfile {
    width: 100%;

    border-radius: 4px;
    box-shadow: -10px 10px 15px rgba(0, 0, 0, 0.15);

    @include desktop {
      box-shadow: -15px 15px 30px rgba(0, 0, 0, 0.15);
    }
  }
}
