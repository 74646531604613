@import "src/styles/variables";
@import "src/design-system/color-palette/color-palette";

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin mobileTitle {
  margin: 0;

  font-size: 32px;
  line-height: 48px;
  color: $base_dark;

  -webkit-text-stroke: 0.2px $base_dark;
}

@mixin desktopTitle {
  margin: 0;

  font-size: 48px;
  line-height: 72px;
  color: $base_dark;

  -webkit-text-stroke: 0.3px $base_dark;
}

@mixin desktopSubTitle {
  margin: 0;

  font-size: 20px;
  font-weight: normal;
  line-height: 28px;
  color: $base_light;
}

@mixin mobileSubTitle {
  font-size: 16px;
  line-height: 28px;
  color: $base_light;
}

@mixin animationHide {
  opacity: 0;

  transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translateY(40px);
}

@mixin animationView {
  opacity: 1 !important;

  transform: translateY(0) !important;
}

.mobile {
  @include desktop {
    display: none !important;
  }
}

.desktop {
  @include mobile {
    display: none !important;
  }
}
