@import "src/design-system/color-palette/color-palette";
@import "src/styles/mixin";

.isOpen {
  .arrowIcon {
    transform: rotate(180deg);
  }

  .title {
    font-weight: bold;
  }

  .contentWrapper {
    padding-bottom: 32px;

    @include desktop {
      padding-bottom: 40px;
      margin-top: 16px;
    }
  }
}

.qnaCollapse {
  align-items: center;

  color: #272e40;

  @include desktop {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .titleWrapper {
    position: relative;

    box-sizing: border-box;
    display: flex;

    align-items: center;
    justify-content: space-between;

    height: 60px;

    cursor: pointer;

    transition: font-weight 0.15s ease-in-out, background-color 0.15s ease-in-out;

    @include desktop {
      height: 68px;
      padding: 0 20px;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $base_gray2;
        border-radius: 8px;
      }
    }

    .title {
      display: flex;

      align-items: center;

      .icon {
        position: relative;
        top: -2px;

        width: 16px;
        height: 16px;
        margin-right: 10px;

        @include desktop {
          width: 16px;
          height: 16px;
          margin-right: 16px;
        }
      }

      .desktopTitle {
        font-size: 18px;
      }
    }

    .arrowIcon {
      width: 16px;
      height: 16px;

      fill: $base_gray4;

      transition: transform 0.15s ease-in-out;

      @include desktop {
        width: 24px;
        height: 24px;
      }
    }
  }

  .contentWrapper {
    overflow: hidden;

    transition: height 0.3s ease-out, opacity 0.3s ease-out;

    .content {
      display: grid;

      gap: 20px;

      padding-left: 26px;

      white-space: pre-line;

      @include desktop {
        padding-left: 54px;
      }

      .desktopContent {
        font-size: 18px;
        line-height: 32px;
        color: $base_primary;
      }
    }
  }
}
