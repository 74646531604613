@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeinUp {
  from {
    opacity: 0;

    transform: translateY(40px);
  }

  to {
    opacity: 1;

    transform: translateY(0);
  }
}


@keyframes copyWriteFadeinUp {
  from {
    opacity: 0;

    transform: translateY(20px);
  }

  to {
    opacity: 1;

    transform: translateY(0);
  }
}


@keyframes qnaFadeinUp {
  from {
    opacity: 0;

    transform: translateY(20px);
  }

  to {
    opacity: 1;

    transform: translateY(0);
  }
}
